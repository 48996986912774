<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">
        Raport achiziții
      </div>
      <div
        class="col2_subHead"
        style="margin-top: 1.2rem; display: flex; justify-content: flex-end;"
      >
        <div class="Button1 Btn_center" @click="generateExcel">
          <div class="icon">
            <i class="fas fa-download"></i>
          </div>
          <span class="Btn_content"> Descarcă </span>
        </div>
      </div>
    </div>
    <template>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="'GET_PAAP_CENTRALIZATION_REPORTS'"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @totalRecords="(x) => (totalRecords = x)"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";

import PermisionsMixin from "../mixins/paapCentralizationPermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import TableParent from "@/components/TableParent";
import {
  GET_PAAP_CENTRALIZATION_REPORTS,
  PAAP_CENTRALIZATION_GENERATE,
} from "../api.js";

export default {
  name: "Achizitii",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent,
  },
  data() {
    return {
      centralization: null,
      tableHead: [
        {
          title: "Poziție PAAP",
          sort: true,
          queryKey: "identifier",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Denumire PAAP",
          queryKey: "name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Suma PAAP",
          sort: true,
          queryKey: "paapCentralization.totalValueWithoutTva",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Suma achiziție poziție PAAP (CMD finalizate)",
          queryKey: "sumAcquisitionApproved",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Suma achiziție poziție PAAP (inițiate)",
          queryKey: "sumAcquisitionInit",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "+/- (finalizate-RN)",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "+/- (inițiate-PAAP)",
          bottomHead: {
            quikFiltre: true,
          },
        },
      ],
      tableModule: (x, y, t) => {
        return GET_PAAP_CENTRALIZATION_REPORTS(x, y);
      },
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    reload() {
      this.reloadTable++;
    },

    prepareData(row) {
      console.log(
        row.id,
        Number(row.needReferateSum),
        Number(row.sumAcquisitionApproved)
      );

      console.log(
        row.id,
        Number(row.needReferateSum),
        Number(row.sumAcquisitionInit)
      );
      const preparation = [
        row.identifier,
        row.name,
        this.toPriceFormat(row.totalValueWithoutTva),
        this.toPriceFormat(row.sumAcquisitionApproved ?? "0"),
        this.toPriceFormat(row.sumAcquisitionInit ?? "0"),
        this.toPriceFormat(row.differenceApproved),
        this.toPriceFormat(row.differenceInit),
      ];
      return preparation;
    },

    generateExcel() {
      PAAP_CENTRALIZATION_GENERATE()
        .then((res) => {
          var blob = new Blob([res], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, `${uuidv4()}.xlsx`);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  computed: {},

  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    //const canViewButtonsColumn = ["edit", "delete"];

    this.updateRoleStatus();
    /*     if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    } */
  },
};
</script>
<style>
.acquisition-docs {
  max-width: 1200px !important;
  max-height: 1200px !important;
}
</style>
